import { createEffect } from 'effector'
import getServicesWithFilters from './services/getServicesWithFilters'

import getServices from './services/getServices'
import getActiveServices from './services/getActiveServices'
import getFirestoreServices from './services/getFirestoreServices'

export const handleGetServices = createEffect(getServices)
export const handleGetFirestoreServices = createEffect(getFirestoreServices)
export const handleGetActiveServices = createEffect(getActiveServices)
export const handleGetServicesWithFilters = createEffect(getServicesWithFilters)
