// import { UserRepository } from 'repositories/UserRepository'

import { setEncryptedStorage } from 'helpers/setEncryptedStorage'

import { FirestoreDbDefaultService, FirestoreService } from '../../firebase'
import { User } from 'firestore/entities/User'

export class UserService {
  private firebaseDbCentralized = new FirestoreDbDefaultService()
  private firestoreService = new FirestoreService()

  convertToInterface(user: User): User {
    return <User>{
      group: user.group,
      name: user.name,
      email: user.email,
      project: user.project,
      role: user.role,
      customer: user.customer,
      client: user.client,
      region: user.region,
      admin: user.admin
    }
  }

  convertToEntity(user: User): User {
    return new User(
      user.group,
      user.name,
      user.email,
      user.project,
      user.role,
      user.customer,
      user.client,
      user.region,
      user.admin
    )
  }

  public async getUserRegionById(id: string): Promise<User | undefined> {
    const responseUser =
      await this.firebaseDbCentralized.getDocumentByIdCentralized('users', id)

    const customerData =
      await this.firebaseDbCentralized.getDocumentByIdCentralized(
        `customers`,
        responseUser?.customer
      )

    setEncryptedStorage('region', customerData?.region)

    delete responseUser?.contestation

    return {
      ...responseUser,
      region: customerData?.region
    } as User
  }

  public async getUserUs(
    id: string,
    region?: string
  ): Promise<User | undefined> {
    const responseUser: any = await this.firestoreService.getDocumentById(
      'users',
      id,
      undefined,
      false,
      region
    )

    return {
      ...responseUser,
      admin: responseUser?.admin
    } as User
  }
}
