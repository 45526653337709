import { useUnit } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function usePayloadAdditional() {
  const { data, business, origin, category } = useUnit(store)

  return {
    data,
    business,
    origin,
    category,
    ...actions
  }
}
