import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('/fonts/segoe-ui.woff') format('woff');
  }

  @font-face {
    font-family: 'Segoe UI SemiBold';
    src: url('/fonts/SegoeUI-Semibold.woff2') format('woff2'),
        url('/fonts/SegoeUI-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


  @font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('/fonts/segoe-ui-italic.woff') format('woff');
  }

  @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('/fonts/segoe-ui-bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('/fonts/segoe-ui-bold-italic.woff') format('woff');
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI Regular', sans-serif;
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color:#EBEBEB;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #898989;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.backGround};
  }

`

export default GlobalStyle
