import { FiltersService } from 'firestore/services/FiltersService'

const getFilters = async (): Promise<any> => {
  try {
    const insightsService = new FiltersService()

    const response = await insightsService.getFiltersByClient()

    return response
  } catch (err) {
    throw err
  }
}

export default getFilters
