import { createStore } from 'effector'

import { IAbout } from './types'

import * as actions from './actions'

export const store = createStore<IAbout>(
  {
    isVisible: false,
    lastDate: '21/03/2025',
    version: 'v2.1.2'
  },
  {
    name: 'about'
  }
)

store.on(actions.toggleAbout, (state) => {
  return {
    ...state,
    isVisible: !state.isVisible
  }
})
