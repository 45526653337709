import { createStore } from 'effector'

import { IPayloadDashboard } from './types'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IUsePayloadDashboard {
  data?: IPayloadDashboard
  isLoading?: boolean
  hasError?: boolean
  showFile?: boolean
}

const date = new Date()

const initDate = `${date.getFullYear()}-01-01`
const endDate = date.toISOString().split('T')[0]

const initialState = {
  data: {
    agent: '',
    service: '',
    initDate,
    endDate
  },
  showFile: false
}
export const store = createStore<IUsePayloadDashboard>(
  {
    ...initialState
  },
  {
    name: 'payload-dashboard'
  }
)

store.on(handleLogout, () => ({
  ...initialState
}))

store.on(actions.handleShowFile, (state) => ({
  ...state,
  showFile: true
}))
store.on(actions.handleHideFile, (state) => ({
  ...state,
  showFile: false
}))

store.on(actions.handleSetPayload, (state, payload) => {
  return {
    ...state,
    data: {
      ...payload
    }
  }
})
