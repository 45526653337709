export const formatFilters = (responseFilters?: any) => {
  const date = new Date()

  const endDate = date.toISOString().split('T')[0]

  const getPastDate = (days: number) => {
    const pastDate = new Date()
    pastDate.setDate(date.getDate() - days)
    return pastDate.toISOString().split('T')[0]
  }

  const initDateYearly = `${date.getFullYear()}-01-01` // Primeiro dia do ano
  const initDateMonthly = new Date(date.getFullYear(), date.getMonth(), 1)
    .toISOString()
    .split('T')[0]
  const initDateWeekly = getPastDate(7)
  const initDate30 = getPastDate(30)
  const initDate60 = getPastDate(60)

  const dates = {
    yearly: {
      initDate: initDateYearly,
      endDate
    },
    monthly: {
      initDate: initDateMonthly,
      endDate
    },
    weekly: {
      initDate: initDateWeekly,
      endDate
    },
    30: {
      initDate: initDate30,
      endDate
    },
    60: {
      initDate: initDate60,
      endDate
    }
  }

  const formattedFilters: any = {
    checklist_match:
      responseFilters?.filters?.productive === true
        ? 'produtivo'
        : responseFilters?.filters?.productive === false
        ? 'improdutivos'
        : '',
    ...dates[responseFilters?.filters?.period ?? 'yearly']
  }
  if (responseFilters?.filters?.ncg) {
    formattedFilters.ncg = responseFilters?.filters?.ncg
  }

  return formattedFilters
}
