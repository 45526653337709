import { createStore } from 'effector'

import { IPayloadAdditional, IPayloadFilters } from './types'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IUsePayloadAdditional {
  data?: IPayloadAdditional
  business: IPayloadFilters
  category: IPayloadFilters
  origin: IPayloadFilters
}

const year = new Date().getFullYear()

const initialState = {
  data: {
    month: '',
    year: year,
    day: '',
    category: '',
    status: '',
    origin: '',
    agent: '',
    initDate: '',
    endDate: ''
  },

  business: {
    data: [],
    isLoading: false,
    hasError: false
  },
  origin: {
    data: [],
    isLoading: false,
    hasError: false
  },
  category: {
    data: [],
    isLoading: false,
    hasError: false
  }
}
export const store = createStore<IUsePayloadAdditional>(
  {
    ...initialState
  },
  {
    name: 'payload-additional'
  }
)

store.on(actions.handleGetOrigin, (state) => ({
  ...state,
  origin: {
    ...state.origin,
    isLoading: true,
    hasError: false
  }
}))

store.on(actions.handleGetOrigin.doneData, (state, data) => ({
  ...state,
  origin: {
    ...state.origin,
    data,
    isLoading: false,
    hasError: false
  }
}))

store.on(actions.handleGetOrigin.fail, (state) => ({
  ...state,
  origin: {
    ...state.origin,
    isLoading: false,
    hasError: true
  }
}))

store.on(actions.handleGetCategory, (state) => ({
  ...state,
  category: {
    ...state.category,
    isLoading: true,
    hasError: false
  }
}))

store.on(actions.handleGetCategory.doneData, (state, data) => ({
  ...state,
  category: {
    ...state.category,
    data,
    isLoading: false,
    hasError: false
  }
}))

store.on(actions.handleGetCategory.fail, (state) => ({
  ...state,
  category: {
    ...state.category,
    isLoading: false,
    hasError: true
  }
}))

store.on(actions.handleGetBusiness, (state) => ({
  ...state,
  business: {
    ...state.business,
    isLoading: true,
    hasError: false
  }
}))

store.on(actions.handleGetBusiness.doneData, (state, data) => ({
  ...state,
  business: {
    ...state.business,
    data,
    isLoading: false,
    hasError: false
  }
}))

store.on(actions.handleGetBusiness.fail, (state) => ({
  ...state,
  business: {
    ...state.business,
    isLoading: false,
    hasError: true
  }
}))

store.on(handleLogout, () => ({
  ...initialState
}))

store.on(actions.handleSetPayload, (state, payload) => {
  return {
    ...state,
    data: {
      ...payload
    }
  }
})
