import { useCallback, useEffect, useState } from 'react'
import * as S from './styles'

import Menu from '../Menu'
import Modal from '../ModalAbout'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'modules/auth/hooks'
import Timer from 'components/Timer'
import { usePayloadDashboard } from 'modules/payloadDashboard/hooks'
import { usePayloadAdditional } from 'modules/payloadAdditional/hooks'
import { usePayloadReports } from 'modules/payloadReports/hooks'
import Loading from 'components/Loading'
import { useServices } from 'modules/services/hooks'

import { formatFilters } from 'helpers/formatFilters'
import { useClientFilters } from 'modules/clientFilters/hooks'

type Props = {
  children?: React.ReactNode
}

const Logged: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const { data, handleLogout } = useAuth()

  const { handleGetFilters } = useClientFilters()

  const url = window.location.href.includes('relatorio')

  useEffect(() => {
    if (!data?.isLogged || !data?.role) {
      handleLogout()
      history.push('/')
    }
  }, [data?.isLogged, data?.role, handleLogout, history])

  const [loading, setLoading] = useState(true)
  const { data: payload, handleSetPayload } = usePayloadDashboard()
  const {
    data: payloadAdditional,
    handleSetPayload: handleSetPayloadAdditional
  } = usePayloadAdditional()
  const { firestoreServices, handleGetFirestoreServices } = useServices()

  const { data: payloadReports, handleSetPayload: handleSetPayloadReports } =
    usePayloadReports()

  const fetchWalletsFx = useCallback(async () => {
    try {
      setLoading(true)

      const [response, responseFilters] = await Promise.all([
        handleGetFirestoreServices(data?.findUserPermission?.services),
        handleGetFilters()
      ])

      const services = [...(response ?? firestoreServices?.data ?? [])].map(
        (wallet) => ({
          name: wallet.name
        })
      )

      const filters = formatFilters(responseFilters ?? {})

      handleSetPayload({
        ...payload,
        ...filters,
        services
      })
      handleSetPayloadReports({
        ...payloadReports,
        ...filters,
        services
      })
      handleSetPayloadAdditional({
        ...payloadAdditional,
        ...filters,
        services
      })
    } catch (error) {
      console.log('Erro ao buscar wallets:', error)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleSetPayload,
    handleSetPayloadAdditional,
    handleSetPayloadReports,
    payload,
    payloadAdditional,
    payloadReports
  ])

  useEffect(() => {
    if (!payload?.services) {
      fetchWalletsFx()
    } else {
      setLoading(false)
    }
  }, [payload?.services, fetchWalletsFx])

  if (loading) return <Loading />

  return (
    <>
      <Modal />
      <Timer />

      <S.Container>
        <Menu />
        <S.Wrap noPadding={url}>{children}</S.Wrap>
      </S.Container>
    </>
  )
}

export default Logged
