import { useUnit } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useClientFilters() {
  const { data } = useUnit(store)

  return {
    data,
    ...actions
  }
}
