import { createEffect, createEvent } from 'effector'
import { IPayloadAdditional } from './types'
import getBusiness from './services/getBusiness'
import getCategory from './services/getCategory'
import getOrigin from './services/getOrigin'

export const handleSetPayload = createEvent<IPayloadAdditional>()

export const handleGetBusiness = createEffect(getBusiness)
export const handleGetCategory = createEffect(getCategory)
export const handleGetOrigin = createEffect(getOrigin)
