export class User {
  email: string
  group: string
  name: string
  project: string
  role: string
  client: string
  customer: string
  region?: string
  contestation?: boolean
  admin?: boolean

  constructor(
    group: string,
    name: string,
    email: string,
    project: string,
    role: string,
    customer: string,
    client: string,
    region?: string,
    contestation?: boolean,
    admin?: boolean
  ) {
    this.group = group
    this.name = name
    this.email = email
    this.project = project
    this.role = role
    this.customer = customer
    this.client = client
    this.region = region
    this.contestation = contestation
    this.admin = admin
  }

  static fromJson(json: any): User {
    return new User(
      json['group'],
      json['name'],
      json['email'],
      json['project'],
      json['role'],
      json['customer'],
      json['client'],
      json['region'],
      json['contestation'],
      json['admin']
    )
  }

  toJson(): any {
    const finalUser = {
      group: this.group,
      name: this.name,
      email: this.email,
      project: this.project,
      customer: this.customer,
      client: this.client,
      region: this.region,
      contestation: this.contestation,
      admin: this.admin
    }

    const newObj = {}

    for (const key in finalUser) {
      if (finalUser[key] !== undefined) {
        newObj[key] = finalUser[key]
      }
    }

    return newObj
  }
}
