export class Wallet {
  active: boolean
  ai_model: string
  checklist: any
  display_name: string
  name: string
  total_limit_minutes: string
  transcription_method: string
  total_limit_tickets?: string
  graphic_role?: string
  service_flow?: string

  constructor(
    active: boolean,
    ai_model: string,
    checklist: any,
    display_name: string,
    name: string,
    transcription_method: string,
    total_limit_minutes: string,
    total_limit_tickets?: string,
    graphic_role?: string,
    service_flow?: string
  ) {
    this.active = active
    this.ai_model = ai_model
    this.checklist = checklist
    this.display_name = display_name
    this.name = name
    this.total_limit_minutes = total_limit_minutes
    this.transcription_method = transcription_method
    this.total_limit_tickets = total_limit_tickets
    this.graphic_role = graphic_role
    this.service_flow = service_flow
  }

  static fromJson(json: any): Wallet {
    return new Wallet(
      json['active'],
      json['ai_model'],
      json['checklist'],
      json['display_name'],
      json['name'],
      json['total_limit_minutes'],
      json['transcription_method'],
      json['total_limit_tickets'],
      json['graphic_role'],
      json['service_flow']
    )
  }

  toJson(): any {
    const finalUser = {
      active: this.active,
      ai_model: this.ai_model,
      checklist: this.checklist,
      display_name: this.display_name,
      name: this.name,
      total_limit_minutes: this.total_limit_minutes,
      transcription_method: this.transcription_method,
      total_limit_tickets: this.total_limit_tickets,
      graphic_role: this.graphic_role,
      service_flow: this.service_flow
    }

    const newObj = {}

    for (const key in finalUser) {
      if (finalUser[key] !== undefined) {
        newObj[key] = finalUser[key]
      }
    }

    return newObj
  }
}
