import { FirestoreDbDefaultService, FirestoreService } from '../../firebase'
import { decryptData } from 'helpers/decryptData'
import { getCookieValue } from 'helpers/cookies'
import { Filters } from 'firestore/entities/Filters'

export class FiltersService {
  private firebaseDbCentralized = new FirestoreDbDefaultService()
  private firebase = new FirestoreService()
  private customer = decryptData(getCookieValue('customer') || '') || ''

  public async getFirestoreFilters(): Promise<{ [key: string]: any }> {
    const clientApplication = await this.firebase.getDocumentById(
      `customers/${this.customer}/application`,
      'filters'
    )
    const defaultApplication =
      await this.firebaseDbCentralized.getDocumentByIdCentralized(
        `application`,
        'filters'
      )

    const clientFilters = clientApplication || {}
    const defaultFilters = defaultApplication || {}

    const mergedFilters = { ...defaultFilters, ...clientFilters }

    return mergedFilters as Filters
  }

  public async getFiltersByClient(): Promise<any> {
    const clientApplication = await this.firebase.getDocumentById(
      `customers/${this.customer}/application/`,
      'config'
    )

    return clientApplication
  }
}
