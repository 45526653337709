import { setCookieValue } from 'helpers/cookies'

import { IAuth } from '../types'
import { axiosInstance, namespaces } from 'services/api'

import CryptoJS from 'crypto-js'
import { Signin } from 'firestore/services/SignIn'

type TPayload = {
  email: string
  password: string
}

const signIn = async (payload: TPayload): Promise<any> => {
  try {
    const { email, password } = payload

    const oAuth = new Signin()
    const response = await oAuth.logIn(email, password)

    const { data: logos } = await axiosInstance.get(
      namespaces.baseURLNameSpaceFiltersClientsColors,
      {
        headers: {
          Authorization: `Bearer ${response.access_token}`
        },
        params: {
          client: response.client
        }
      }
    )

    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${response.access_token}`

    axiosInstance.interceptors.request.use((config) => {
      const auth = `Bearer ${response.access_token}`

      config.headers.Authorization = auth

      return config
    })

    const secretKey: string = process.env.REACT_APP_NAMESPACE_NAME ?? '' // Use environment variable or a secure way to store your secret key

    const encryptData = (data: any) =>
      CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()

    const encryptedData = {
      role: encryptData(response.role || secretKey),
      client: encryptData(response.client),
      admin: encryptData(response.admin ?? false),
      project: encryptData(response.project),
      userEmail: encryptData(response.email),
      dynamics: encryptData(response.dynamics_graphics),
      userPermission: encryptData(response.findUserPermission),
      customer: encryptData(response.customer),
      isAllowedContestation: encryptData(response.isAllowedContestation),
      key: encryptData(password),
      region: encryptData(response.region)
    }

    setCookieValue(namespaces.TOKEN_NAME, response.access_token || '')
    setCookieValue('role', encryptedData.role || '')
    setCookieValue('client', encryptedData.client || '')
    setCookieValue(namespaces.REFRESH_TOKEN_NAME, response.refreshToken || '')
    setCookieValue('isLogged', true || '')
    setCookieValue('project', encryptedData.project || '')
    setCookieValue(namespaces.LAST_REFRESH, response.lastRefresh)
    setCookieValue('customer', encryptedData.customer)
    setCookieValue('admin', encryptedData.admin)
    setCookieValue('email', encryptedData.userEmail || '')
    localStorage.setItem(
      'findUserPermission',
      encryptedData.userPermission || ''
    )
    localStorage.setItem(
      'usersColors',
      JSON.stringify({ ...response.usersColors, ...logos }) || ''
    )
    localStorage.setItem('key', encryptedData.key)

    localStorage.setItem('dynamics_graphics', encryptedData.dynamics || '')

    localStorage.setItem(
      'isAllowedContestation',
      `${encryptedData.isAllowedContestation}`
    )

    const responseData: IAuth = {
      access_token: response.access_token,
      email: response.email,
      name: response.name,
      role: response.role || secretKey,
      client: response.client || '',
      project: response.project || '',
      refreshToken: response.refreshToken,
      isLogged: true,
      lastRefresh: response.lastRefresh,
      usersColors: JSON.stringify({ ...response.usersColors, ...logos }),
      dynamics_graphics: response.dynamics_graphics,
      findUserPermission: response.findUserPermission,
      region: response.region || '',
      admin: response.admin,
      isAllowedContestation: response.isAllowedContestation
    }

    return responseData
  } catch (err) {
    throw err
  }
}

export default signIn
