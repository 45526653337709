// import { UserRepository } from 'repositories/UserRepository'

import { FirestoreDbDefaultService, FirestoreService } from '../../firebase'

import { Wallet } from 'firestore/entities/Wallet'
import { getCookieValue } from 'helpers/cookies'
import { decryptData } from 'helpers/decryptData'

export class WalletService {
  private firestoreService = new FirestoreService()
  private firestoreDefault = new FirestoreDbDefaultService()
  private customer = decryptData(getCookieValue('customer') || '')

  convertToInterface(wallet: Wallet): Wallet {
    return <Wallet>{
      active: wallet.active,
      ai_model: wallet.ai_model,
      checklist: wallet.checklist,
      display_name: wallet.display_name,
      name: wallet.name,
      transcription_method: wallet.transcription_method,
      total_limit_minutes: wallet.total_limit_minutes,
      total_limit_tickets: wallet.total_limit_tickets,
      graphic_role: wallet.graphic_role,
      service_flow: wallet.service_flow
    }
  }

  convertToEntity(wallet: Wallet): Wallet {
    return new Wallet(
      wallet.active,
      wallet.ai_model,
      wallet.checklist,
      wallet.display_name,
      wallet.name,
      wallet.transcription_method,
      wallet.total_limit_minutes,
      wallet.total_limit_tickets,
      wallet.graphic_role,
      wallet.service_flow
    )
  }

  public async getWallets(services?: Array<string>): Promise<any> {
    const responseWallets = await this.firestoreService.findDocumentByQuery(
      `customers/${this.customer}/services`,
      'active',
      '==',
      true
    )

    if (services && services?.length > 0) {
      return responseWallets
        .map((wallet) => this.convertToInterface(wallet.data))
        .filter((item) => {
          const foundWallet = services?.find(
            (service) => service.toLowerCase() === item.name.toLowerCase()
          )

          if (foundWallet) {
            return item
          }
        })
    }

    return responseWallets.map((wallet) => this.convertToInterface(wallet.data))
  }

  public async getWalletRole(service: string): Promise<any> {
    const [responseWallets, application] = await Promise.all([
      this.firestoreService.findDocumentByQuery(
        `customers/${this.customer}/services`,
        'name',
        '==',
        service
      ),
      this.firestoreDefault.getDocumentByIdCentralized(
        '/application',
        'graphic_roles'
      )
    ])

    const wallets = responseWallets.map((wallet) =>
      this.convertToInterface(wallet.data)
    )

    const graphicRole = application

    let foundGraphicPermissions: any = undefined

    foundGraphicPermissions =
      graphicRole && graphicRole[wallets[0]?.graphic_role ?? '']

    if (!foundGraphicPermissions) {
      if (graphicRole) {
        Object.keys(graphicRole).forEach(function (chave) {
          if (
            foundGraphicPermissions === undefined &&
            graphicRole[chave]?.default === true
          ) {
            foundGraphicPermissions = graphicRole[chave]
          }
        })
      }
    }

    return foundGraphicPermissions
  }
}
