import CryptoJS from 'crypto-js'

export const setEncryptedStorage = (key: string, value: any) => {
  const secretKey: string = process.env.REACT_APP_NAMESPACE_NAME ?? '' // Use environment variable or a secure way to store your secret key

  const encryptData = (data: any) =>
    CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()

  return localStorage.setItem(key, encryptData(value))
}
