import { createStore } from 'effector'

import { IServices } from './types'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IUseServices {
  data?: IServices
  isLoading?: boolean
  hasError?: boolean
  isLoadingWithFilters: boolean
  hasErrorWithFilters: boolean
  dataWithFilters: IServices
  isLoadingActiveServices: boolean
  hasErrorActiveServices: boolean
  dataActiveServices: IServices
  firestoreServices: {
    isLoading: boolean
    hasError: boolean
    data: any
  }
}

const initialState = {
  isLoading: false,
  hasError: false,
  data: [
    {
      label: 'Selecionar',
      value: ''
    }
  ],
  isLoadingWithFilters: false,
  hasErrorWithFilters: false,
  dataWithFilters: [
    {
      label: 'Selecionar',
      value: ''
    }
  ],

  isLoadingActiveServices: false,
  hasErrorActiveServices: false,
  dataActiveServices: [
    {
      label: 'Selecionar',
      value: ''
    }
  ],
  firestoreServices: {
    isLoading: true,
    hasError: false,
    data: []
  }
}

export const store = createStore<IUseServices>(
  {
    ...initialState
  },
  {
    name: 'services'
  }
)

store.on(handleLogout, () => ({
  ...initialState
}))

store.on(actions.handleGetServices, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleGetServices.doneData, (state, data) => ({
  ...state,
  data,
  isLoading: false,
  hasError: false
}))

store.on(actions.handleGetServices.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleGetServicesWithFilters, (state) => ({
  ...state,
  isLoadingWithFilters: true,
  hasErrorWithFilters: false
}))

store.on(actions.handleGetServicesWithFilters.doneData, (state, data) => ({
  ...state,
  dataWithFilters: data,
  isLoadingWithFilters: false,
  hasErrorWithFilters: false
}))

store.on(actions.handleGetServicesWithFilters.fail, (state) => ({
  ...state,
  isLoadingWithFilters: false,
  hasErrorWithFilters: true
}))

store.on(actions.handleGetActiveServices, (state) => ({
  ...state,
  isLoadingActiveServices: true,
  hasErrorActiveServices: false
}))

store.on(actions.handleGetActiveServices.doneData, (state, data) => ({
  ...state,
  dataActiveServices: data,
  isLoadingActiveServices: false,
  hasErrorActiveServices: false
}))

store.on(actions.handleGetActiveServices.fail, (state) => ({
  ...state,
  isLoadingActiveServices: false,
  hasErrorActiveServices: true
}))

store.on(actions.handleGetFirestoreServices, (state) => ({
  ...state,
  firestoreServices: {
    ...state.firestoreServices,
    isLoading: true
  }
}))

store.on(actions.handleGetFirestoreServices.doneData, (state, data) => ({
  ...state,
  firestoreServices: {
    ...state.firestoreServices,
    isLoading: false,
    hasError: false,
    data: data
  }
}))

store.on(actions.handleGetFirestoreServices.fail, (state) => ({
  ...state,
  firestoreServices: {
    ...state.firestoreServices,
    isLoading: false,
    hasError: true
  }
}))
