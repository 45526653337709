import { initializeApp } from 'firebase/app'

import {
  GoogleAuthProvider,
  getAuth,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth'

import {
  collection,
  query,
  where,
  getDocs,
  WhereFilterOp,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  initializeFirestore,
  Firestore,
  OrderByDirection,
  orderBy,
  limit,
  QueryConstraint
} from 'firebase/firestore'

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes
} from 'firebase/storage'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { decryptData } from 'helpers/decryptData'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_NAMESPACE_API_KEY,
  authDomain: process.env.REACT_APP_NAMESPACE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_NAMESPACE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_NAMESPACE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_NAMESPACE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_NAMESPACE_APP_ID
}

const appDefault = initializeApp(firebaseConfig)

const initializeFirestoreInstance = (dbId: string) => {
  const firestoreInstance = initializeFirestore(
    appDefault,
    {
      ignoreUndefinedProperties: true,
      experimentalAutoDetectLongPolling: true,
      experimentalLongPollingOptions: { timeoutSeconds: 30 }
    },
    dbId
  )

  return firestoreInstance
}

const dbDefault = initializeFirestoreInstance('(default)')
const dbClient = {
  us: initializeFirestoreInstance('hvoice-us'),
  br: initializeFirestoreInstance('hvoice-br')
}

const auth = getAuth(appDefault)

if (!auth.currentUser) {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      console.log('Persistência configurada com sucesso.')
    })
    .catch((error) => {
      console.error('Erro ao configurar a persistência:', error)
    })
} else {
  console.log('Usuário já autenticado, persistência não configurada novamente.')
}

export enum ECollections {}
// USER = 'users',

export interface IGetReport {
  year: number
  month: number
}

export class OAuthService {
  private auth = getAuth(appDefault)

  provider = new GoogleAuthProvider()

  // TODO: Verificar retorno
  public async firebaseSignInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<any> {
    try {
      const response: UserCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      )

      return response
    } catch (error: any) {
      throw error
    }
  }

  public async createUserInOAuth(email: string): Promise<UserCredential> {
    const currentUser = this.auth.currentUser

    const newUserCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      crypto.randomUUID()
    )

    if (currentUser) {
      await this.auth.updateCurrentUser(currentUser)
    } else {
      await this.auth.signOut()
    }

    return newUserCredential
  }

  public async getDocumentByIdDefault(
    collectionName: string,
    docId: string,
    filters?: { field: string; operator: WhereFilterOp; value: any }[]
  ) {
    try {
      const db: Firestore = dbDefault
      const collectionRef = collection(db, collectionName)

      if (filters && filters.length > 0) {
        const queryConstraints: QueryConstraint[] = filters.map((filter) =>
          where(filter.field, filter.operator, filter.value)
        )

        const q = query(collectionRef, ...queryConstraints)
        const querySnapshot = await getDocs(q)

        if (querySnapshot.empty) {
          return null
        }

        return querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data()
        }))
      } else {
        const docRef = doc(collectionRef, docId)
        const docSnap = await getDoc(docRef)
        return docSnap.exists()
          ? { id: docSnap.id, data: docSnap.data() }
          : null
      }
    } catch (e) {
      throw new Error(`Erro ao buscar documento. ${e}`)
    }
  }
}

export class FirestoreDbDefaultService {
  public collection
  public converter
  public region

  constructor(collection?: ECollections, converter?) {
    this.collection = collection
    this.converter = converter
  }

  public async getAllDocumentsByDbCentralized(collectionName: string) {
    try {
      const querySnapshot = await getDocs(collection(dbDefault, collectionName))

      const documents = querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id
      }))
      return documents
    } catch (e) {
      throw new Error(`${e}`)
    }
  }
  public async getDocumentByIdCentralized(
    collectionName: string,
    docId: string
  ) {
    try {
      const docRef = doc(dbDefault, collectionName, docId)
      const docSnap = await getDoc(docRef)
      return docSnap.data()
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  public async getDocumentById(
    collectionName: string,
    docId: string,
    dbDev?: boolean
  ) {
    try {
      const docRef = doc(
        dbDev ? dbDefault : dbClient[this.region],
        collectionName,
        docId
      )
      const docSnap = await getDoc(docRef)
      return docSnap.data()
    } catch (e) {
      throw new Error(`${e}`)
    }
  }
}
export class FirestoreService {
  public collection
  public converter
  public region

  constructor(collection?: ECollections, converter?) {
    this.collection = collection
    this.converter = converter
    this.region = decryptData(localStorage.getItem('region') || '')
  }

  //    NEW FUNCTIONS WITH CONVERTER

  public async getDocById<T>(docId: string, dbDev?: boolean): Promise<T> {
    try {
      const docRef = doc(
        dbDev ? dbDefault : dbClient[this.region],
        this.collection,
        docId
      ).withConverter(this.converter)
      return (await getDoc(docRef)).data() as T
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  public async getDocs<T>(
    filter?: {
      field: string
      operator: WhereFilterOp
      value: string | boolean | string[]
    },
    dbDev?: boolean
  ): Promise<T[]> {
    try {
      let filterData
      if (filter) {
        filterData = where(filter.field, filter.operator, filter.value)
      }
      const q = query(
        collection(dbDev ? dbDefault : dbClient[this.region], this.collection),
        filterData
      ).withConverter(this.converter)

      const QuerySnapshot = await getDocs(q)
      return QuerySnapshot.docs.map((x) => x.data() as T)
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  public async setDoc<T>(
    collectionPath: string,
    entity: any, // TODO: Receber uma entidade
    documentId: string,
    dbDev?: boolean
  ): Promise<any> {
    const document = doc(
      dbDev ? dbDefault : dbClient[this.region],
      collectionPath,
      documentId
    ).withConverter(this.converter)
    return (await setDoc(document, entity)) as T
  }

  public async addDoc<T>(
    collectionPath: string,
    entity: any,
    dbDev?: boolean
  ): Promise<any> {
    return (await addDoc(
      collection(
        dbDev ? dbDefault : dbClient[this.region],
        collectionPath
      ).withConverter(this.converter),
      entity
    )) as T
  }

  // OLD FUNCTIONS

  public async findDocumentByQuery(
    collectionName: string,
    field?: string,
    operator?: WhereFilterOp,
    value?: string | boolean | string[],
    orderByField?: string,
    orderDirection: OrderByDirection = 'desc',
    limitValue?: number,
    dbDev?: boolean
  ): Promise<any[]> {
    try {
      const db: Firestore = dbDev ? dbDefault : dbClient[this.region]
      const collectionRef = collection(db, collectionName)
      const queryConstraints: QueryConstraint[] = []

      if (field && operator !== undefined && value !== undefined) {
        if (Array.isArray(value)) {
          queryConstraints.push(where(field, 'in', value))
        } else {
          queryConstraints.push(where(field, operator, value))
        }
      }

      if (orderByField) {
        queryConstraints.push(orderBy(orderByField, orderDirection))
      }

      if (limitValue) {
        queryConstraints.push(limit(limitValue))
      }

      const q = query(collectionRef, ...queryConstraints)
      const querySnapshot = await getDocs(q)

      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data()
      }))
    } catch (error) {
      throw new Error(`Erro ao buscar documentos. ${error}`)
    }
  }
  public async getDocumentById(
    collectionName: string,
    docId?: string, // Agora pode ser opcional
    filters?: { field: string; operator: WhereFilterOp; value: any }[],
    dbDev?: boolean,
    region?: string
  ) {
    try {
      const db: Firestore = dbDev ? dbDefault : dbClient[region ?? this.region]
      const collectionRef = collection(db, collectionName)

      if (docId) {
        const docRef = doc(collectionRef, docId)
        const docSnap = await getDoc(docRef)
        return docSnap.exists() ? docSnap.data() : null
      }

      if (filters && filters.length > 0) {
        const queryConstraints: QueryConstraint[] = filters.map((filter) =>
          where(filter.field, filter.operator, filter.value)
        )

        const q = query(collectionRef, ...queryConstraints)
        const querySnapshot = await getDocs(q)

        return querySnapshot.docs.map((doc) => doc.data())
      }

      throw new Error(
        'É necessário fornecer um docId ou filtros para buscar documentos.'
      )
    } catch (e) {
      throw new Error(`Erro ao buscar documento. ${e}`)
    }
  }

  public async getAllDocuments(collectionName: string, useDbDefault?: boolean) {
    try {
      const querySnapshot = await getDocs(
        collection(
          useDbDefault ? dbDefault : dbClient[this.region],
          collectionName
        )
      )

      const documents = querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id
      }))
      return documents
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  public async setDocument(
    collectionPath: string,
    data: any,
    documentId?: string,
    dbDev?: boolean
  ): Promise<any> {
    try {
      const result =
        documentId != undefined
          ? await setDoc(
              doc(
                dbDev ? dbDefault : dbClient[this.region],
                collectionPath,
                documentId
              ),
              data
            )
          : await addDoc(
              collection(
                dbDev ? dbDefault : dbClient[this.region],
                collectionPath
              ),
              data
            )
      return result
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  public async updateDocument(
    collectionPath: string,
    data: any,
    documentId: string,
    dbDev?: boolean
  ): Promise<any> {
    try {
      const documentRef = doc(
        dbDev ? dbDefault : dbClient[this.region],
        collectionPath,
        documentId
      )

      await updateDoc(documentRef, data, {
        merge: true
      })

      return data
    } catch (e) {
      throw new Error(`Erro ao atualizar o documento: ${e}`)
    }
  }

  public async updateSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    subdocumentId: string,
    data: any,
    dbDev?: boolean
  ): Promise<any> {
    try {
      const documentRef = doc(
        dbDev ? dbDefault : dbClient[this.region],
        collectionPath,
        documentId,
        subcollectionPath,
        subdocumentId
      )
      const response = await updateDoc(documentRef, data)
      return response
    } catch (error) {
      throw new Error(`${error}`)
    }
  }

  public async createSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    data: any,
    dbDev?: boolean
  ): Promise<any> {
    try {
      const documentRef = doc(
        dbDev ? dbDefault : dbClient[this.region],
        collectionPath,
        documentId
      )

      const subcollectionRef = collection(documentRef, subcollectionPath)
      const newDocumentRef = await addDoc(subcollectionRef, data)

      return newDocumentRef
    } catch (error) {
      throw new Error(`${error}`)
    }
  }

  public async deleteDocument(
    collectionPath: string,
    documentId: string,
    dbDev?: boolean
  ): Promise<void> {
    try {
      const documentRef = doc(
        collection(dbDev ? dbDefault : dbClient[this.region], collectionPath),
        documentId
      )
      await deleteDoc(documentRef)
    } catch (error) {
      throw new Error(`${error}`)
    }
  }

  public async disableDocumentVirtually(
    collectionPath: string,
    documentId: string,
    dbDev?: boolean
  ): Promise<any> {
    try {
      const disabeDoc = { is_active: false }
      const result = updateDoc(
        doc(
          dbDev ? dbDefault : dbClient[this.region],
          collectionPath,
          documentId
        ),
        disabeDoc
      )
      return result
    } catch (e) {
      throw new Error(`${e}`)
    }
  }
}

export class StorageService {
  public async deleteDocInBucket(storagePath: string): Promise<void> {
    try {
      const storage = getStorage()
      const imageRef = ref(storage, storagePath)

      await deleteObject(imageRef)
    } catch (error: any) {
      throw new Error(`Erro ao remover a imagem: ${error.message}`)
    }
  }

  public async uploadImage(
    imageData: Blob,
    storagePath: string
  ): Promise<string> {
    try {
      const storage = getStorage()
      const hash = this.generateUniqueName()

      const imageRef = ref(storage, `${storagePath}/${hash}`)

      await uploadBytes(imageRef, imageData)

      const downloadURL = await getDownloadURL(imageRef)

      return downloadURL
    } catch (error: any) {
      throw new Error(`Erro ao enviar a imagem: ${error.message}`)
    }
  }

  public async getImagesInFolder(storagePath: string) {
    const storage = getStorage(appDefault)
    const bucketRef = ref(storage, `/${storagePath}`)

    try {
      const files = await listAll(bucketRef)

      const urlsImagens = await Promise.all(
        files.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef)
          return url
        })
      )

      return urlsImagens
    } catch (error) {
      console.error('Erro ao listar arquivos na pasta do bucket:', error)
      // TODO: Trate o erro adequadamentes
      throw error
    }
  }

  public generateUniqueName(): string {
    // Gere um nome de arquivo único usando timestamp e um número aleatório
    const timestamp = new Date().getTime()
    const randomNumber = Math.floor(Math.random() * 10000)
    return `${timestamp}_${randomNumber}`
  }
}

export class FunctionService {
  public async getReport({ year, month }: IGetReport) {
    const functions = getFunctions()
    const addMessage = httpsCallable(functions, 'onReportRequest')
    let response
    await addMessage({ year: Number(year), month: Number(month) })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data

        response = data
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code
        const message = error.message
        const details = error.details

        response = `${code} ${message} ${details}`
        // ...
      })

    return response
  }
}
