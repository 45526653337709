import { useUnit } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useServices() {
  const {
    data,
    hasError,
    isLoading,
    isLoadingWithFilters,
    dataWithFilters,
    hasErrorWithFilters,
    dataActiveServices,
    hasErrorActiveServices,
    isLoadingActiveServices,
    firestoreServices
  } = useUnit(store)

  return {
    data,
    hasError,
    isLoading,
    isLoadingWithFilters,
    hasErrorWithFilters,
    dataWithFilters,
    dataActiveServices,
    hasErrorActiveServices,
    isLoadingActiveServices,
    firestoreServices,
    ...actions
  }
}
