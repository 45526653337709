import { FirestoreService, OAuthService } from '../../firebase'
import { IAuth } from 'modules/auth/types'
import { UserService } from './UserService'

export class Signin {
  private userService = new UserService()

  // TODO: Quebrar em varias funções e armazenar no storage na propria função e não no final
  public async logIn(email: string, password: string): Promise<IAuth> {
    try {
      const oAuth = new OAuthService()
      const { user } = await oAuth.firebaseSignInWithEmailAndPassword(
        email,
        password
      )

      const userInfo = await this.userService.getUserRegionById(user.uid)

      if (!userInfo) {
        throw new Error('User info not found')
      }

      const firestoreService = new FirestoreService()

      const [
        graphicsPermissions,
        applicationClient,
        applicationDefaultRole1s,
        userUs
      ] = await Promise.all([
        firestoreService.getAllDocuments(
          `/application/graphic_definitions/services`,
          true
        ),
        firestoreService.getDocumentById(
          `/customers/${userInfo.customer}/application`,
          'portal_color'
        ),
        firestoreService.getDocumentById(
          `/application`,
          'roles',
          [{ field: 'name', operator: '==', value: userInfo.role }],
          true
        ),
        this.userService.getUserUs(user.uid, userInfo?.region)
      ])

      const roles = applicationDefaultRole1s

      let userColors

      if (applicationClient && applicationClient[userInfo.client]) {
        userColors = applicationClient && applicationClient[userInfo.client]
      } else {
        userColors = applicationClient
      }

      const findUserPermission = this.getUserPermission(
        roles,
        userInfo?.role || ''
      )

      const lastRefresh = new Date()

      return this.buildAuthResponse(
        user,
        { ...userInfo, ...userUs },
        findUserPermission,
        userColors,
        graphicsPermissions,
        lastRefresh
      )
    } catch (err: any) {
      throw new Error(
        `Authentication failed: ${err.message || 'Unknown error'}`
      )
    }
  }

  private getUserPermission(roles: any, userRole: string): any {
    let permission = roles[userRole] ?? undefined

    if (!permission) {
      Object.keys(roles).forEach((role) => {
        if (!permission && roles[role]?.default === true) {
          permission = roles[role]
        }
      })
    }

    return permission
  }

  private buildAuthResponse(
    user: any,
    userInfo: any,
    findUserPermission?: any,
    userColor?: any,
    graphicsPermissions?: any,
    lastRefresh?: Date
  ): IAuth {
    return {
      ...user,
      isAllowedContestation:
        userInfo?.contestation !== undefined ? userInfo.contestation : true,
      id: user.uid,
      access_token: user.accessToken,
      email: userInfo?.email || '',
      customer: userInfo?.customer || '',
      name: userInfo?.name || '',
      role: userInfo?.role || '',
      client: userInfo?.client || '',
      project: userInfo?.project || '',
      admin: userInfo.admin ?? false,
      region: userInfo.region || '',
      refreshToken: user.refreshToken,
      isLogged: true,
      lastRefresh,
      usersColors: userColor || {},
      dynamics_graphics: graphicsPermissions || {},
      findUserPermission
    }
  }
}
