import { createStore } from 'effector'

import { IPayloadReports } from './types'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IUsePayloadReports {
  data?: IPayloadReports
  isLoading?: boolean
  hasError?: boolean
}

const date = new Date()

const initialState = {
  data: {
    agent: '',
    year: date.getFullYear(),
    initDate: `${date.getFullYear() - 1}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`,
    endDate: `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`,
    service: '',
    services: []
  }
}

export const store = createStore<IUsePayloadReports>(
  {
    ...initialState
  },
  {
    name: 'payload-dashboard'
  }
)

store.on(handleLogout, () => ({
  ...initialState
}))

store.on(actions.handleSetPayload, (state, payload) => {
  return {
    ...state,
    data: {
      ...payload
    }
  }
})
