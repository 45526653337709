import styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'
import LoaderComponent from '../../Icons/Loader'

import { IButtonProps } from './interfaces'

export const Loader = styled(LoaderComponent)``

export const Container = styled.button<IButtonProps>`
  all: unset;

  box-sizing: border-box;

  padding: ${({ noPadding }) => (noPadding ? '12px 31px' : '12px 82px')};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  min-height: 45px;
  min-width: ${({ noPadding }) => (noPadding ? 'auto' : '215px')};

  border-radius: 10px;
  font-family: 'Segoe UI SemiBold';
  font-size: ${rem(16)};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;

  ${(props) =>
    props.color &&
    {
      primary: css`
        background-color: ${({ theme }) =>
          props.secondary_color ? props.secondary_color : theme.colors.green};
      `,
      secondary: css`
        background-color: ${({ theme }) =>
          props.backgroundColor ? props.backgroundColor : theme.colors.blue1};
      `
    }[props.color]};

  ${({ fill, color, backgroundColor, secondary_color }) =>
    fill === 'outline' &&
    css`
      ${() =>
        color &&
        {
          primary: css`
            border: solid 1px #cbcbcb;
            ${Loader} {
              border-color: ${secondary_color
                ? secondary_color
                : rgba('#32DE8A', 0.2)};
              border-left-color: ${secondary_color
                ? secondary_color
                : '#32de8a'};
            }
            border-color: ${({ theme }) =>
              secondary_color ? secondary_color : theme.colors.green};
            color: ${({ theme }) =>
              secondary_color ? secondary_color : theme.colors.green};
            background: transparent;
          `,
          secondary: css`
            border: solid 1px;
            ${Loader} {
              border-color: ${backgroundColor
                ? backgroundColor
                : rgba('#4C00FF', 0.2)};
              border-left-color: #4c00ff;
            }
            border-color: ${({ theme }) =>
              backgroundColor ? backgroundColor : theme.colors.blue1};
            color: ${({ theme }) =>
              backgroundColor ? backgroundColor : theme.colors.blue1};
            background: transparent;
          `
        }[color]};
    `}

  :hover {
    box-shadow: 0 3px 6px #00000029;
    transition: 0.3s;
  }

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`
