import { createStore } from 'effector'

import { IPayloadAdditional } from './types'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IUsePayloadAdditional {
  data?: IPayloadAdditional
}

const initialState = {
  data: {}
}
export const store = createStore<IUsePayloadAdditional>(
  {
    ...initialState
  },
  {
    name: 'payload-additional'
  }
)

store.on(actions.handleGetFilters, (state) => ({
  ...state,
  data: {
    ...state.data,
    isLoading: true,
    hasError: false
  }
}))

store.on(actions.handleGetFilters.doneData, (state, data) => ({
  ...state,
  data: {
    ...state.data,
    data,
    isLoading: false,
    hasError: false
  }
}))

store.on(actions.handleGetFilters.fail, (state) => ({
  ...state,
  data: {
    ...state.data,
    isLoading: false,
    hasError: true
  }
}))

store.on(handleLogout, () => ({
  ...initialState
}))
