import { axiosInstance, namespaces } from 'services/api'

import { IServices } from '../types'

const getServicesWithFilters = async (payload?: any): Promise<IServices> => {
  const currentYear = new Date().getFullYear()

  try {
    const response = await axiosInstance.get(
      namespaces.baseURLNamespaceOperationsServicesWithFilters,
      {
        params: {
          ...payload,
          isCriticalTherm: '',
          service: payload?.wallet || payload?.service,
          token: payload?.word || payload?.token,
          year: payload?.year === '' ? `${currentYear}` : payload?.year,
          month: payload?.month !== '0' ? payload?.month : ''
        }
      }
    )

    const { data } = response
    const handleSortValues = (options: string[]) => {
      const optionsOrdened = options.sort((prevItem: any, item: any) => {
        let index = -1
        if (prevItem.name > item.name) index = 1

        return index
      })
      return optionsOrdened
    }
    const dataSorted = handleSortValues(data ?? [])

    const responseData: IServices = dataSorted?.map((item: any) => ({
      label: item.display_name,
      value: item.name,
      selected: false
    }))

    return [
      {
        label: 'Selecionar',
        value: '',
        selected: true
      },
      ...responseData
    ]
  } catch (err) {
    throw err
  }
}

export default getServicesWithFilters
