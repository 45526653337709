import { createStore } from 'effector'
import { getCookieValue, removeCookieValue } from 'helpers/cookies'
import { namespaces } from 'services/api'
import CryptoJS from 'crypto-js'

import { IAuth } from './types'

import * as actions from './actions'
import { decryptData } from 'helpers/decryptData'

interface IUseAuth {
  data?: IAuth
  isLoading?: boolean
  hasError?: boolean
}

export const store = createStore<IUseAuth>(
  {
    isLoading: false,
    hasError: false,
    data: {
      isLogged: !!getCookieValue('isLogged'),
      name: '',
      admin: (() => {
        const admin = getCookieValue('admin')
        if (admin) {
          try {
            const bytes = decryptData(admin)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      email: (() => {
        const email = getCookieValue('email')
        if (email) {
          try {
            const bytes = decryptData(email)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      role: (() => {
        const role = getCookieValue('role')

        if (role) {
          try {
            const bytes = decryptData(role)

            return bytes
          } catch (error) {
            return ''
          }
        } else {
          return ''
        }
      })(),
      client: (() => {
        const client = getCookieValue('client')
        if (client) {
          try {
            const secretKey = process.env.REACT_APP_NAMESPACE_NAME ?? ''
            const bytes = CryptoJS.AES.decrypt(client, secretKey)
            const decryptedRoles = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            return decryptedRoles
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      access_token: getCookieValue(namespaces.TOKEN_NAME),
      refreshToken: getCookieValue(namespaces.REFRESH_TOKEN_NAME),
      usersColors: localStorage.getItem('usersColors'),
      isAllowedContestation: (() => {
        const isAllowedContestation = localStorage.getItem(
          'isAllowedContestation'
        )

        if (isAllowedContestation) {
          try {
            const bytes = decryptData(isAllowedContestation)

            return bytes
          } catch (error) {
            return true
          }
        } else {
          return true
        }
      })(),

      dynamics_graphics: (() => {
        const dynamics_graphics = localStorage.getItem('dynamics_graphics')

        if (dynamics_graphics) {
          try {
            const bytes = decryptData(dynamics_graphics)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      project: (() => {
        const project = localStorage.getItem('project')
        if (project) {
          try {
            const bytes = decryptData(project)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      findUserPermission: (() => {
        const findUserPermission = localStorage.getItem('findUserPermission')
        if (findUserPermission) {
          try {
            const bytes = decryptData(findUserPermission)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })(),
      customer: (() => {
        const customer = localStorage.getItem('customer')
        if (customer) {
          try {
            const bytes = decryptData(customer)

            return bytes
          } catch (error) {
            return []
          }
        } else {
          return []
        }
      })()
    }
  },
  {
    name: 'auth'
  }
)

store.on(actions.handleSignIn, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleSignIn.doneData, (state, data) => ({
  ...state,
  data,
  isLoading: false,
  hasError: false
}))

store.on(actions.handleSignIn.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleRefreshToken, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleRefreshToken.doneData, (state, data) => ({
  ...state,
  data: {
    ...state.data,
    ...data
  },
  isLoading: false,
  hasError: false
}))

store.on(actions.handleRefreshToken.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleLogout, (state) => {
  removeCookieValue(namespaces.TOKEN_NAME)
  removeCookieValue(namespaces.LAST_REFRESH)
  removeCookieValue(namespaces.REFRESH_TOKEN_NAME)
  removeCookieValue('email')
  removeCookieValue('role')
  removeCookieValue('menu')
  removeCookieValue('client')
  removeCookieValue('isLogged')
  removeCookieValue('customer')
  removeCookieValue('project')
  removeCookieValue('admin')

  localStorage.removeItem('isAllowedContestation')
  localStorage.removeItem('usersColors')
  localStorage.removeItem('findUserPermission')
  localStorage.removeItem('region')
  localStorage.removeItem('key')
  localStorage.removeItem('dynamics_graphics')

  window.location.reload()

  return {
    ...state,
    data: {
      isLogged: false
    }
  }
})
