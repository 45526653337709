import moment from "moment"
import { getCookieValue } from "helpers/cookies"

import { handleLogout } from "modules/auth/actions"

import NotFoundException from './exceptions/NotFoundException'
import BadRequestException from './exceptions/BadRequestException'
import InternalServerErrorException from './exceptions/InternalServerErrorException'


export const API_KEY = process.env.REACT_APP_API_KEY

export const baseURL = process.env.REACT_APP_BASE_URL
export const baseURLNamespaceKpi = process.env.REACT_APP_NAMESPACE_KPI || ""
export const baseURLNamespaceKpiGraphTypeAudio = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_TYPE_AUDIO || ""
export const baseURLNamespaceKpiGraphTypeAudioChat = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_TYPE_AUDIO_CHAT || ""

export const baseURLNamespaceKpiAssertivenessComparative = process.env.REACT_APP_NAMESPACE_KPI_ASSERTIVENESS_COMPARATIVE || ""
export const baseURLNamespaceKpiAssertivenessComparativeChat = process.env.REACT_APP_NAMESPACE_KPI_ASSERTIVENESS_COMPARATIVE_CHAT || ""

export const baseURLNamespaceKpiGraphCuradoria = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_CURADORIA || ""
export const baseURLNamespaceKpiGraphCuradoriaChat = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_CURADORIA_CHAT || ""

export const baseURLNamespaceKpiGraphOffenders = process.env.REACT_APP_NAMESPACE_KPI_OFFENDERS_GRAPH || ""
export const baseURLNamespaceKpiGraphOffendersChat = process.env.REACT_APP_NAMESPACE_KPI_OFFENDERS_GRAPH_CHAT || ""

export const baseURLNamespaceKpiServiceRole = process.env.REACT_APP_NAMESPACE_FILTERS_SPECIFC_SERVICE_ROLE || ""
export const baseURLNamespaceKpiSilenceGraph = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH || ""

export const baseURLNamespaceKpiGraphReasonNoBusiness = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_REASON_NO_BUSINESS || ""

export const baseURLNamespaceKpiSilenceGraphPercentTs = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_PERCENT_TS || ""
export const baseURLNamespaceKpiSilenceGraphReadinessMaxContinuosOperator = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_READINESS_MAX_CONTINUOS_OPERATOR || ""
export const baseURLNamespaceKpiSilenceGraphReadinessMaxContinuos = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_READINESS_MAX_CONTINUOS || ""
export const baseURLNamespaceKpiSilenceGraphTmaMonth = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_TMA_MONTH || ""
export const baseURLNamespaceKpiSilenceGraphOperator = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_OPERATOR || ""
export const baseURLNamespaceKpiSilenceMetrics = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_METRICS || ""
export const baseURLNamespaceKpiSilenceGraphTmaOperator = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_TMA_OPERATOR || ""

export const baseURLNamespaceKpiGraphInterestPayDebt = process.env.REACT_APP_NAMESPACE_KPI_GRAPH_INTEREST_PAY_DEBT || ""
export const baseURLNamespaceMinutes = process.env.REACT_APP_NAMESPACE_MINUTES || ""
export const baseURLNamespaceMinutesGraph = process.env.REACT_APP_NAMESPACE_MINUTES_GRAPH_YEAR || ""
export const baseURLNamespaceOperations = process.env.REACT_APP_NAMESPACE_OPERATIONS || ""
export const baseURLNamespaceOperationsServices = process.env.REACT_APP_NAMESPACE_OPERATIONS_SERVICES || ""
export const baseURLNamespaceActiveServices = process.env.REACT_APP_NAMESPACE_ACTIVE_SERVICES || ""
export const baseURLNamespaceOperationsAgents = process.env.REACT_APP_NAMESPACE_OPERATIONS_AGENTS || ""
export const baseURLNamespaceOperationsServicesWithFilters = process.env.REACT_APP_NAMESPACE_OPERATIONS_SERVICES_WITH_FILTERS || ""

export const baseURLNamespaceKpiAssertivenessChecklistWeight = process.env.REACT_APP_NAMESPACE_KPI_ASSERTIVENESS_CHECKLIST_WEIGHT || ""

export const baseURLNameSpaceCsrGraphOneOperator = process.env.REACT_APP_NAMESPACE_CSR_GRAPH_ONE_OPERATOR || ""
export const baseURLNameSpaceCsrServices = process.env.REACT_APP_NAMESPACE_CSR_SERVICES || ""
export const baseURLNameSpaceCsrAgents = process.env.REACT_APP_NAMESPACE_CSR_AGENTS || ""
export const baseURLNameSpaceCsrCalls = process.env.REACT_APP_NAMESPACE_CSR_CALLS || ""
export const baseURLNameSpaceCsrContacts = process.env.REACT_APP_NAMESPACE_CSR_CONTACTS || ""
export const baseURLNameSpaceCsrGraphs = process.env.REACT_APP_NAMESPACE_CSR_GRAPHS || ""
export const baseURLNameSpaceCsrOperators = process.env.REACT_APP_NAMESPACE_CSR_OPERATORS || ""
export const baseURLNameSpaceCsrAgentsByMonth = process.env.REACT_APP_NAMESPACE_CSR_AGENTS_BY_MONTH || ""
export const baseURLNameSpaceCsrMacroMotif = process.env.REACT_APP_NAMESPACE_CSR_MACRO_MOTIF || ""
export const baseURLNameSpaceCsrMacroDetail = process.env.REACT_APP_NAMESPACE_CSR_MACRO_DETAIL || ""
export const baseURLNameSpaceCsrCustomerAction = process.env.REACT_APP_NAMESPACE_CSR_CUSTOMER_ACTION || ""
export const baseURLNameSpaceCsrAgentAction = process.env.REACT_APP_NAMESPACE_CSR_AGENT_ACTION || ""
export const baseURLNameSpaceCsrCustomerIntention = process.env.REACT_APP_NAMESPACE_CSR_CUSTOMER_INTENTION || ""
export const baseURLNameSpaceCsrMacroDetailReport = process.env.REACT_APP_NAMESPACE_CSR_MACRO_DETAIL_REPORT || ""
export const baseURLNameSpaceCsrProductiveFilters = process.env.REACT_APP_NAMESPACE_CSR_PRODUCTIVE_FILTERS || ""
export const baseURLNameSpaceFilters = process.env.REACT_APP_NAMESPACE_FILTERS || ""
export const baseURLNameSpaceFiltersClientsColors = process.env.REACT_APP_NAMESPACE_FILTERS_CLIENTS_COLORS || ""
export const baseURLNameSpaceFiltersPermissions = process.env.REACT_APP_NAMESPACE_FILTERS_CLIENTS_PERMISSIONS || ""
export const baseURLNameSpaceFiltersPermissionsDynamicGraphics = process.env.REACT_APP_NAMESPACE_FILTERS_PERMISSIONS_DYNAMIC_GRAPHICS || ""
export const baseURLNameSpaceFiltersInsights = process.env.REACT_APP_NAMESPACE_KPI_INSIGHTS || ""
export const baseURLNameSpaceFiltersInsightsAudios = process.env.REACT_APP_NAMESPACE_KPI_INSIGHTS_AUDIOS || ""
export const baseURLNameSpaceFiltersInsightNeedleGraphic = process.env.REACT_APP_NAMESPACE_KPI_INSIGHTS_NEEDLE_GRAPHIC || ""


export const baseURLNameSpaceAdditionalFilter = process.env.REACT_APP_NAMESPACE_ADDITIONAL_FILTER || ""

export const baseURLNamespaceOperationsProfile = process.env.REACT_APP_NAMESPACE_OPERATIONS_PROFILE || ""
export const baseURLNamespaceOperationsStatus = process.env.REACT_APP_NAMESPACE_OPERATIONS_STATUS || ""
export const baseURLNamespacePredictions = process.env.REACT_APP_NAMESPACE_PREDICTIONS || ""
export const baseURLNamespaceUploadsMetadata = process.env.REACT_APP_NAMESPACE_UPLOADS_METADATA || ""
export const baseURLNamespaceUploadsAudios = process.env.REACT_APP_NAMESPACE_UPLOADS_AUDIOS || ""
export const baseURLNamespaceUploadsLastUploads = process.env.REACT_APP_NAMESPACE_UPLOADS_LAST_UPLOADS || ""
export const baseURLNamespaceUsers = process.env.REACT_APP_NAMESPACE_USERS || ""
export const baseURLNamespaceOcurrences = process.env.REACT_APP_NAMESPACE_OCURENCES || ""
export const baseURLNamespaceOcurrencesServices = process.env.REACT_APP_NAMESPACE_OCURENCES_SERVICES || ""
export const baseURLNamespaceOcurrencesMonth = process.env.REACT_APP_NAMESPACE_OCURENCES_MONTH || ""
export const baseURLNamespaceOcurrencesAudios = process.env.REACT_APP_NAMESPACE_OCURENCES_AUDIOS || ""

export const baseURLNamespaceOcurrencesChat = process.env.REACT_APP_NAMESPACE_OCURENCES_CHAT || ""
export const baseURLNamespaceKpiChat = process.env.REACT_APP_NAMESPACE_KPI_CHAT || ""
export const baseURLNamespaceOCurrencesAudiosChat = process.env.REACT_APP_NAMESPACE_OCURENCES_AUDIOS_CHAT || ""
export const baseURLNamespaceKpiSilenceGraphChat = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_CHAT || ""
export const baseURLNamespaceKpiSilenceMetricsChat = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_METRICS_CHAT || ""
export const baseURLNamespaceKpiSilenceGraphTmaMonthChat = process.env.REACT_APP_NAMESPACE_KPI_SILENCE_GRAPH_TMA_MONTH_CHAT || ""
export const baseURLNamespaceOperatorsChat = process.env.REACT_APP_NAMESPACE_OPERATORS_CHAT || ""

export const baseURLNamespaceOperatorsConsolidate = process.env.REACT_APP_NAMESPACE_OPERATORS_CONSOLIDATE || ""
export const baseURLNamespaceOperatorAgentWithScore = process.env.REACT_APP_NAMESPACE_OPERATORS_AGENT_WITH_SCORE || ""
export const baseURLNamespaceOperatorsSegregateByItems = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_ITEMS || ""
export const baseURLNamespaceOperatorsSegregateByItemsSpread = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_ITEMS_SPREAD || ""

export const baseURLNamespaceOperatorsSegregateByCallItems = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_CALL_ITEMS || ""
export const baseURLNamespaceOperatorsSegregateByCallItemsName = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_CALL_ITEMS_NAME || ""
export const baseURLNamespaceOperatorsSegregateByCallItemsUniqueId = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_CALL_ITEMS_UNIQUEID || ""
export const baseURLNamespaceOperatorsSegregateByCallItemsContestation = process.env.REACT_APP_NAMESPACE_OPERATORS_SEGREGATE_BY_CALL_ITEMS_CONTESTATION || ""

export const baseURLLogin = process.env.REACT_APP_BASE_LOGIN || ""
export const baseURLRefreshToken = process.env.REACT_APP_BASE_REFRESH_TOKEN || ""

export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || ''
export const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_TOKEN_NAME || ''
export const TIME_TO_REFRESH_TOKEN = parseInt(process.env.REACT_APP_TIME_TO_REFRESH_TOKEN) || 2


export const LAST_REFRESH = process.env.REACT_APP_LAST_REFRESH || ''
export const OPERATORS = process.env.REACT_APP_OPERATORS || ''


export const responseInterceptor = response => response

export const errorMessages = {
  400: 'Algo deu errado. Tente novamente mais tarde.',
  404: 'Recurso não encontrado.',
  500: 'Erro de comunicação interna. Tente novamente mais tarde.',
  0: 'Erro genérico, exception não tratada.',
}

export const generateErrorDataWithMessage = (statusCode, errorData) => {
  if (errorData) {
    if (Object.entries(errorData).length > 0 && errorData !== '{}') {
      if (Object.prototype.hasOwnProperty.call(errorData, 'msg')) {
        return JSON.stringify(errorData)
      }
      return JSON.stringify({
        ...errorData,
        msg: errorMessages[statusCode],
      })
    }
    return JSON.stringify({ msg: errorMessages[statusCode] })
  }
  return JSON.stringify({ msg: errorMessages[statusCode] })
}

export const responseErrorInterceptor = error => {
  const HTTPStatusCode = error.response.status
  const errorData = error.response.data

  switch (true) {
    case HTTPStatusCode === 400:
      throw errorData
    case HTTPStatusCode === 401 || HTTPStatusCode === 403:
      throw errorData
    case HTTPStatusCode === 404:
      throw new NotFoundException(generateErrorDataWithMessage(404, errorData))
    case HTTPStatusCode >= 500:
      throw new InternalServerErrorException(
        generateErrorDataWithMessage(500, errorData)
      )
    default:
      throw new Error(
        JSON.stringify({
          msg: `HTTP status code: ${HTTPStatusCode} - ${errorMessages[0]}`,
        })
      )
  }

  return Promise.reject(error.response)
}

export const requestInterceptor = async request => {
  if (!getCookieValue(TOKEN_NAME)) {
    return request
  }

  request.headers.Authorization = `Bearer ${getCookieValue(TOKEN_NAME)}`
  return request
}
