import { axiosInstance, namespaces } from 'services/api'

const getOrigin = async (payload?: any): Promise<any> => {
  try {
    const { data } = await axiosInstance.get(
      `${namespaces.baseURLNameSpaceAdditionalFilter}origin`,
      {
        params: {
          ...payload
        }
      }
    )

    const dateFormate = [
      {
        label: 'Todos',
        value: '',
        selected: true
      }
    ]

    dateFormate.push(
      ...data?.map((item) => {
        return { label: item.filter, value: item.filter, selected: false }
      })
    )

    return dateFormate
  } catch (err) {
    throw err
  }
}

export default getOrigin
