import { WalletService } from 'firestore/services/WalletService'

const getFirestoreServices = async (services?: Array<string>): Promise<any> => {
  try {
    const walletService = new WalletService()

    const response = await walletService.getWallets(services)

    return response
  } catch (err) {
    throw err
  }
}

export default getFirestoreServices
