import { axiosInstance, namespaces } from 'services/api'

import { IServices } from '../types'

const getActiveServices = async (): Promise<IServices> => {
  try {
    const response = await axiosInstance.get(
      namespaces.baseURLNamespaceActiveServices
    )

    const { data } = response

    const responseData: IServices = data.map((item) => ({
      label: item.display_name,
      value: item.name
    }))

    return [
      {
        label: 'Selecionar',
        value: '',
        selected: true
      },
      ...responseData
    ]
  } catch (err) {
    throw err
  }
}

export default getActiveServices
